import {getTerm,getClass} from '@/api/public/search'

const modules = {
    state: {
        queryForm: {
            termName: sessionStorage['termName'] ? sessionStorage['termName'] : '', 
            deptId: sessionStorage['deptId'] ? sessionStorage['deptId'] : '', 
        },
        termlist: sessionStorage['termlist'] ? JSON.parse(sessionStorage['termlist']) : [],
        classlist: sessionStorage['classlist'] ? JSON.parse(sessionStorage['classlist']) : [],
    },
    mutations:{
        SET_TERMNAME(state,termName){
            state.queryForm.termName = termName
            sessionStorage['termName'] = termName
        },
        SET_DEPTID(state,deptId){
            state.queryForm.deptId = deptId
            sessionStorage['deptId'] = deptId
        },
        SET_TERMLIST(state,termlist) {
            state.termlist = termlist
            sessionStorage['termlist'] = JSON.stringify(termlist)
        },
        SET_CLASSLIST(state,classlist) {
            state.classlist = classlist
            sessionStorage['classlist'] = JSON.stringify(classlist)
        },
    },
    actions: {
        GetTermList({commit}){
            return new Promise((resolve,reject) => {
                getTerm({}).then(res => {
                    commit('SET_TERMLIST', res.data)
                    if(res.data.length > 0){
                        const termName = res.data[0].termName
                        commit('SET_TERMNAME', termName)
                    }
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        GetClassList({commit},typeId){
            return new Promise((resolve,reject) => {
                getClass({typeId: typeId}).then(res => {
                    commit('SET_CLASSLIST', res.data)
                    if(res.data.length > 0) {
                       const deptId = res.data[0].deptId
                       commit('SET_DEPTID', deptId)
                    }
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        ClearGetters({commit}) {
            commit('SET_TERMLIST', [])
            commit('SET_CLASSLIST', [])
            commit('SET_TERMNAME', '')
            commit('SET_DEPTID', '')
            sessionStorage.clear()
        }
    },
}

export default modules