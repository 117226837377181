import {
    getToken,
    setToken,
    removeToken
} from '@/utils/auth'
import {
    imageSrc,
} from '@/utils/ruoyi'
import {
    login,
    logout,
    getUserinfo,
    getTokenAgain
} from '@/api/public/login'
import {
	getAllUnread
} from '@/api/public/message'

const modules = {
    namespaced: true,
    state: {
        token: getToken(),
        refresh_token: sessionStorage['refresh_token'] ? sessionStorage['refresh_token'] : '',
        userInfo: {},
        headUrl: '',
        name: '',
        userId: '',
        roles: [],
        dept: {},
        reviewAuthoritys: [],
        headTeacher: '',
        student: [],
        roleName: '',
        deptName: '',
        loginName: ''
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_REFRESH_TOKEN: (state, refresh_token) => {
            state.refresh_token = refresh_token
            sessionStorage['refresh_token'] = refresh_token
        },
        SET_USERINFO: (state, userInfo) => {
            state.userInfo = userInfo
        },
        SET_HEADURL: (state, headUrl) => {
            state.headUrl = headUrl
        },
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_USERID: (state, userId) => {
            state.userId = userId
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_DEPT: (state, dept) => {
            state.dept = dept
        },
        SET_AUTHORITYS: (state, author) => {
            state.reviewAuthoritys = author
        },
        SET_HEADTEACHER: (state, isheader) => {
            state.headTeacher = isheader
        },
        SET_STUDENT: (state,student) => {
            state.student = student
        },
        SET_ROLENAME: (state,rolename) => {
            state.roleName = rolename
        },
        SET_DEPTNAME: (state,deptName) => {
            state.deptName = deptName
        },
        SET_LOGINNAME: (state,loginname) => {
            state.loginName = loginname
        }
    },
    actions: {
        login({
            commit
        }, user) {
            const {
                username,
                password
            } = user;
            return new Promise((resolve, reject) => {
                login({
                    username: username,
                    password: password
                }).then(res => {
                    removeToken()
                    const {
                        data
                    } = res
                    const token = data.token_type + ' ' + data.access_token
                    const refresh_token = data.refresh_token
                    commit('SET_TOKEN', token)
                    commit('SET_REFRESH_TOKEN', refresh_token)
                    setToken(token)
                    resolve()
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getUserInfo({ commit,  state }, payload = {}) {
            return new Promise((resolve, reject) => {
                getUserinfo( payload ).then(res => {
                    commit('SET_HEADURL', res.data.headUrl)
                    commit('SET_USERINFO', res.data)
                    commit('SET_NAME', res.data.userName)
                    commit('SET_USERID', res.data.userId)
                    commit('SET_ROLES', res.data.roles)
                    commit('SET_DEPT', res.data.dept)
                    commit('SET_AUTHORITYS', res.data.reviewAuthoritys)
                    commit('SET_HEADTEACHER', res.data.headTeacher)
                    if(res.data.studentId) {
                        commit('SET_STUDENT', JSON.parse(res.data.studentId))
                    }
                    commit('SET_ROLENAME', res.data.roles[0].roleName)
                    if(res.data.dept.ancestorsName) {
                        commit('SET_DEPTNAME', res.data.dept.ancestorsName.split(',')[1])
                    }else {
                        commit('SET_DEPTNAME', res.data.dept.ancestorsName)
                    }
                    commit('SET_LOGINNAME', res.data.loginName)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        logout({
            commit
        }) {
            return new Promise(resolve => {
                logout().then(res => {
                    // console.log(res,"aa")
                    commit('SET_TOKEN', '')
                    commit('SET_USERINFO', {})
                    commit('SET_NAME', '')
                    commit('SET_USERID', '')
                    commit('SET_ROLES', [])
                    commit('SET_DEPT', {})
                    commit('SET_AUTHORITYS', [])
                    commit('SET_HEADTEACHER', '')
                    commit('SET_ROLENAME', '')
                    commit('SET_DEPTNAME', '')
                    commit('SET_LOGINNAME', '')
                    sessionStorage.clear()
                    removeToken()
                    resolve()
                })
            })
        },
		// 获取所有未读消息数量
		getAllUnreadMsgNum({ commit }) {
			return new Promise(resolve => {
			    getAllUnread({}).then(res => {
					sessionStorage.setItem('unReadMsgNum', JSON.stringify(res.data))
					resolve(res.data)
			    })
			})
		},
        getAgain({commit,state}) {
            return new Promise((resolve,reject)=> {
                getTokenAgain({refresh_token: state.refresh_token}).then(res => {
                    const {
                        data
                    } = res
                    const token = data.token_type + ' ' + data.access_token
                    const refresh_token = data.refresh_token
                    commit('SET_TOKEN', token)
                    commit('SET_REFRESH_TOKEN', refresh_token)
                    setToken(token)
                    resolve()
                }).catch(err => {
                    reject(err)
                })
            })
        }
    }
}

export default modules