import {post} from '@/utils/axios'

// 获取个人消息
export const getPersonal = params => post('/shida-user-biz/TbMessagePerson/getData', params)

// 个人消息已读
export const readPersonal = params => post('/shida-user-biz/TbMessagePerson/saveUserHaveRead', params)

// 获取系统消息
export const getSystem = params => post('/shida-user-biz/TbMessageSystem/getAcceptMessage',params)

// 系统消息已读
export const readSystem = params => post('/shida-user-biz/TbMessageSystem/saveSystemMessageByUserId',params)

// 获取政策公告
export const getPolicy = params => post('/shida-user-biz/TbMessagePolicy/getDataByClient',params)

// 政策公告已读
export const readPolicy = params => post('/shida-user-biz/TbMessagePolicyUser/saveOrUpdate',params)

// 获取政策未读数量
export const getPolicyUnread = params => post('/shida-user-biz/TbMessagePolicy/getUnReadPolicyNum',params)

// 所有消息总数
export const getAllUnread = params => post('/shida-user-biz/TbMessagePolicyUser/getTotalMessage',params)
