require('es6-promise').polyfill();
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {post,get} from './utils/axios'
import { parseTime, resetForm, addDateRange, download, getDaysBetween, getLocalDate,splitDate,photoCompress,canvasDataURL,convertBase64UrlToBlob,compressImg,imageSrc, isEmpty, fileNotImg, getUnReadMsgNum, ltToday, tableHeaderColor, cellStyleFun } from "@/utils/ruoyi";
import Pagination from './components/Pagination'
import JsonExcel from 'vue-json-excel'
import "./assets/iconfont/iconfont.css"

// Echarts 按需引入
import * as echarts from 'echarts/core';
import {
  DatasetComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import {
  BarChart,
  PieChart,
} from 'echarts/charts';
import {
  CanvasRenderer
} from 'echarts/renderers';

echarts.use(
  [DatasetComponent, TooltipComponent, GridComponent, LegendComponent, BarChart, PieChart, CanvasRenderer]
);

import './permission'

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.$post = post
Vue.prototype.$get = get
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.download = download
Vue.prototype.getDaysBetween = getDaysBetween
Vue.prototype.getLocalDate = getLocalDate
Vue.prototype.splitDate = splitDate
Vue.prototype.photoCompress = photoCompress
Vue.prototype.canvasDataURL = canvasDataURL
Vue.prototype.convertBase64UrlToBlob = convertBase64UrlToBlob
Vue.prototype.compressImg = compressImg
Vue.prototype.imageSrc = imageSrc
Vue.prototype.isEmpty = isEmpty
Vue.prototype.fileNotImg = fileNotImg
Vue.prototype.tableHeaderColor = tableHeaderColor
Vue.prototype.cellStyleFun = cellStyleFun
// 写实记录文字内容字数
Vue.prototype.realisticLen = {
	max: 500,
	min: 50
}
Vue.prototype.getUnReadMsgNum = getUnReadMsgNum
Vue.prototype.ltToday = ltToday()

Vue.use(ElementUI)
Vue.component('Pagination', Pagination)
Vue.component("downloadExcel", JsonExcel)

Vue.directive('focus', {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function (el) {
      // 聚焦元素
      // console.log(el);
      el.children[0].focus()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
