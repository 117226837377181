import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken, removeToken } from '@/utils/auth'

NProgress.configure({showSpinner: false})

const whiteList = ['/login', '/forgetPwd']

router.beforeEach((to,from,next) => {
    NProgress.start()
    if(getToken()){
        if(to.path === '/login') {
            next('/index')
            NProgress.done()
        }else {
            if(store.getters.roles.length === 0){
                let parents = {}
                if(sessionStorage.getItem('parents')) {
                    parents = JSON.parse(sessionStorage.getItem('parents'))
                }
                store.dispatch('user/getUserInfo', parents).then((res)=>{
					let roleK = store.getters.roles[0].roleKey
					if (roleK != 'ban_wei' && roleK != 'xin_li_plan') {
						store.dispatch('user/getAllUnreadMsgNum')
					}
                    next()
                }).catch(err => {
                    // store.dispatch('user/logout').then(() => {
                        removeToken()
                        sessionStorage.clear()
                        next({ path: '/' })
                    // })
                })
            }else {
                next()
            }
        }
    }else {
        // 没有token
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        }else {
            next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
            NProgress.done() 
        }
    }
})

router.afterEach(()=>{
    NProgress.done()
})