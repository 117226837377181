import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/public/login/login.vue')
  },
  {
    path: '/forgetPwd',
    name: 'forgetPwd',
    component: () => import('../views/public/login/forgetPwd.vue')
  },
  {
    path: '/skip',
    name: 'skip',
    component: () => import('../views/public/skip.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../layout/index.vue'),
    redirect: '/index',
    children: [
      // 公共路由
      {
        path: '/index',
        name: 'index',
        component: () => import('../views/public/index/index.vue')
      },
      {
        path: '/message',
        name: 'message',
        component: () => import('../views/public/message/index.vue'),
        redirect: '/message/policymsg',
        children: [
          {
            path: 'policymsg',
            name: 'policymsg',
            component: () => import('../views/public/message/policymsg/index.vue')
          },
          {
            path: 'sysmsg',
            name: 'sysmsg',
            component: () => import('../views/public/message/sysmsg/index.vue')
          },
          {
            path: 'permsg',
            name: 'permsg',
            component: () => import('../views/public/message/permsg/index.vue')
          },
        ],
      },
      {
        path: '/personalCenter',
        name: 'personalCenter',
        component: () => import('../views/public/personalCenter/index.vue')
      },

      // 教师路由
      {
        path: '/dynamic',
        name: 'dynamic',
        component: () => import('../views/teacher/dynamic/dynamic.vue'),
        redirect: '/dynamic/personal',
        children: [
          {
            path: 'personal',
            name: 'personal',
            component: () => import('../views/teacher/dynamic/personal/index.vue')
          },
          {
            path: 'group',
            name: 'group',
            component: () => import('../views/teacher/dynamic/group/index.vue')
          },
        ]
      },
      {
        path: '/quality',
        name: 'quality',
        component: () => import('../views/teacher/quality/quality.vue'),
        redirect: '/quality/summary',
        children: [
          {
            path: 'summary',
            name: 'summary',
            component: () => import('../views/teacher/quality/summary')
          },
          {
            path: 'summaryDetail',
            name: 'summaryDetail',
            component: () => import('../views/teacher/quality/summary/summaryDetail.vue')
          },
          {
            path: 'progress',
            name: 'progress',
            component: () => import('../views/teacher/quality/progress')
          },
          {
            path: 'morality',
            name: 'morality',
            component: () => import('../views/teacher/quality/morality'),
          },
          // 学生成绩详情 --> 思想品德
          {
            path: 'studentResults',
            name: 'studentResults',
            component: () => import('../views/teacher/quality/morality/component/resultsSummary/studentResults.vue')
          },
          {
            path: 'Academic',
            name: 'Academic',
            component: () => import('../views/teacher/quality/Academic')
          },
          // 学生成绩详情 --> 学业水平
          {
            path: 'academicStudent',
            name: 'academicStudent',
            component: () => import('../views/teacher/quality/Academic/component/resultsSummary/academicStudent.vue')
          },
          // 查看成绩 --> 平时成绩
          {
            path: 'usualDetails',
            name: 'usualDetails',
            component: () => import('../views/teacher/quality/Academic/component/usualResults/usualDetails.vue')
          },
          {
            path: 'health',
            name: 'health',
            component: () => import('../views/teacher/quality/health')
          },
          // 学生成绩详情 --> 身心健康
          {
            path: 'healthStudent',
            name: 'healthStudent',
            component: () => import('../views/teacher/quality/health/component/resultsSummary/healthStudent.vue')
          },
          {
            path: 'Artistic',
            name: 'Artistic',
            component: () => import('../views/teacher/quality/Artistic')
          },
          // 学生成绩详情 --> 艺术修养
          {
            path: 'artStudent',
            name: 'artStudent',
            component: () => import('../views/teacher/quality/Artistic/component/resultsSummary/artStudent.vue')
          },
          {
            path: 'social',
            name: 'social',
            component: () => import('../views/teacher/quality/social')
          },
          // 学生成绩详情 --> 社会实践
          {
            path: 'socialStudent',
            name: 'socialStudent',
            component: () => import('../views/teacher/quality/social/component/resultsSummary/socialStudent.vue')
          },
          // 心得感悟 --> 社会实践
          {
            path: 'experience',
            name: 'experience',
            component: () => import('../views/teacher/quality/social/component/socialPractice/experience.vue')
          },
          {
            path: 'indexSystem',
            name: 'indexSystem',
            component: () => import('../views/teacher/quality/indexSystem')
          },
          {
            path: 'psychology',
            name: 'psychology',
            component: () => import('../views/teacher/quality/psychology')
          },
          {
            path: 'reading',
            name: 'reading',
            component: () => import('../views/teacher/quality/reading')
          },
          {
            path: 'video',
            name: 'video',
            component: () => import('../views/teacher/quality/videoRead')
          },
          {
            path: 'graduation',
            name: 'graduation',
            component: () => import('../views/teacher/quality/graduation')
          },
		  {
			path: 'midPhyExam',
			name: 'midPhyExam',
			component: () => import('../views/teacher/quality/health/component/midPhyExam/index.vue')
		  }
        ]
      },
      {
        path: '/manager',
        name: 'manager',
        component: () => import('../views/teacher/manager/manager.vue'),
        redirect: '/manager/studentManage',
        children: [
          {
            path: 'studentManage',
            name: 'studentManage',
            component: () => import('../views/teacher/manager/studentManage')
          },
          {
            path: 'creditManage',
            name: 'creditManage',
            component: () => import('../views/teacher/manager/creditManage')
          },
          {
            path: 'archivesManage',
            name: 'archivesManage',
            component: () => import('../views/teacher/manager/archivesManage')
          },
		  {
			path: 'archivesManageDoc',
			name: 'archivesManageDoc',
			component: () => import('../views/teacher/manager/archivesManage/docDetail')  
		  },
		  {
			path: 'psyHealthTeachPlan',
			name: 'psyHealthTeachPlan',
			component: () => import('../views/teacher/manager/psyHealthTeachPlan')  
		  },
		  {
			path: 'zsSubAdmin',
			name: 'zsSubAdmin',
			component: () => import('../views/teacher/manager/zsSubAdmin')
		  }
        ]
      },
	  // 班委
	  {
		path: '/bwQuality',
		name: 'bwQuality',
		component: () => import('../views/banwei/quality/quality.vue'),
		redirect: '/bwQuality/bwMora',
		children: [
			{
				path: 'bwMora',
				name: 'bwMora',
				component: () => import('../views/banwei/quality/morality')
			},
			{
				path: 'bwMoraHonorRec',
				name: 'bwMoraHonorRec',
				component: () => import('../views/banwei/quality/morality/component/honorRecord/index.vue')
			},
			{
				path: 'bwAcRace',
				name: 'bwAcRace',
				component: () => import('../views/banwei/quality/Academic/component/competition/index.vue')
			},
			{
				path: 'bwAc',
				name: 'bwAc',
				component: () => import('../views/banwei/quality/Academic')
			},
			{
				path: 'bwAcRace',
				name: 'bwAcRace',
				component: () => import('../views/banwei/quality/Academic/component/competition/index.vue')
			},
			{
				path: 'bwArt',
				name: 'bwArt',
				component: () => import('../views/banwei/quality/Artistic')
			},
			{
				path: 'bwArtRace',
				name: 'bwArtRace',
				component: () => import('../views/banwei/quality/Artistic/component/artRace/index.vue')
			},
			{
				path: 'bwHealth',
				name: 'bwHealth',
				component: () => import('../views/banwei/quality/health')
			},
			{
				path: 'bwHealthRace',
				name: 'bwHealthRace',
				component: () => import('../views/banwei/quality/health/component/competition/index.vue')
			}
		]
	  },
	  {
		path: '/bwDynamic',
		name: 'bwDynamic',
		component: () => import('../views/banwei/dynamic/dynamic.vue'),
		redirect: '/bwDynamic/personal',
		children: [
			{
			  path: 'personal',
			  name: 'personal',
			  component: () => import('../views/banwei/dynamic/personal/index.vue')
			}
		]
	  },
	  // 心理健康教育老师
	  {
		path: '/ptTeachPlan',
		name: 'ptTeachPlan',
		component: () => import('../views/psyTeacher/manager/manager.vue'),
		redirect: '/ptTeachPlan/planDetail',
		children: [
			{
			  path: 'planDetail',
			  name: 'planDetail',
			  component: () => import('../views/psyTeacher/manager/psyHealthTeachPlan')
			}
		]
	  },
      // 学生路由
      {
        path: '/classmate',
        name: 'classmate',
        component: () => import('../views/student/classmate/classmate.vue'),
        redirect: '/classmate/personalc',
        children: [
          {
            path: 'personalc',
            name: 'personalc',
            component: () => import('../views/student/classmate/personal/index.vue')
          },
          {
            path: 'groupc',
            name: 'groupc',
            component: () => import('../views/student/classmate/group/index.vue')
          },
        ]
      },
      {
        path: '/myrelease',
        name: 'myrelease',
        component: () => import('../views/student/myrelease/myrelease.vue'),
        redirect: '/myrelease/myrecord',
        children: [
          {
            path: 'myrecord',
            name: 'myrecord',
            component: () => import('../views/student/myrelease/myrecord/index.vue')
          },
          {
            path: 'sort',
            name: 'sort',
            component: () => import('../views/student/myrelease/sort/index.vue')
          },
        ]
      },
      {
        path: '/myquality',
        name: 'myquality',
        component: () => import('../views/student/myquality/myquality.vue'),
        redirect: '/myquality/huizong',
        children: [
          {
            path: 'huizong',
            name: 'huizong',
            component: () => import('../views/student/myquality/huizong/index.vue')
          },
          {
            path: 'jindu',
            name: 'jindu',
            component: () => import('../views/student/myquality/jindu/index.vue')
          },
          {
            path: 'pinde',
            name: 'pinde',
            component: () => import('../views/student/myquality/pinde/index.vue')
          },
          {
            path: 'xueye',
            name: 'xueye',
            component: () => import('../views/student/myquality/xueye/index.vue')
          },
          {
            path: 'shenxin',
            name: 'shenxin',
            component: () => import('../views/student/myquality/shenxin/index.vue')
          },
          {
            path: 'yishu',
            name: 'yishu',
            component: () => import('../views/student/myquality/yishu/index.vue')
          },
          {
            path: 'shehui',
            name: 'shehui',
            component: () => import('../views/student/myquality/shehui/index.vue')
          },
          {
            path: 'tixi',
            name: 'tixi',
            component: () => import('../views/student/myquality/tixi/index.vue')
          },
          {
            path: 'xinli',
            name: 'xinli',
            component: () => import('../views/student/myquality/xinli/index.vue')
          },
          {
            path: 'tuijian',
            name: 'tuijian',
            component: () => import('../views/student/myquality/tuijian/index.vue')
          },
          {
            path: 'yingshi',
            name: 'yingshi',
            component: () => import('../views/student/myquality/yingshi/index.vue')
          },
          {
            path: 'dangan',
            name: 'dangan',
            component: () => import('../views/student/myquality/dangan/index.vue')
          },
		  {
		    path: 'midExamPhy',
		    name: 'midExamPhy',
		    component: () => import('../views/student/myquality/midExamPhy/index.vue')
		  },
        ]
      },
    ]
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/student/goTest/test.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/student/goTest/detail.vue')
  },
  {
    path: '/questionnaire',
    name: 'questionnaire',
    component: () => import('../views/teacher/questionnaire/index.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
