const getters = {
    token: state => state.user.token,
    userInfo: state => state.user.userInfo,
    name: state => state.user.name,
    loginName: state => state.user.loginName,
    userId: state => state.user.userId,
    roles: state => state.user.roles,
    permissions: state => state.user.permissions,
    dept: state => state.user.dept,
    reviewAuthoritys: state => state.user.reviewAuthoritys,
    headTeacher: state => state.user.headTeacher,
    student: state => state.user.student,
    queryForm: state => state.search.queryForm,
    termlist: state => state.search.termlist,
    classlist: state => state.search.classlist,
}

export default getters